.sv-profile-progress {
   cursor: pointer;
}

.sv-profile-progress .sv-progress-bar {
   position: relative;
   margin: 0 0 1.5em;
   width: calc(100% - 20px);
   height: 20px;
   background-color: whiteSmoke;
   border: none;
   border-radius: 3px;
   box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
}

.sv-profile-progress i.question-sign {
   display: block;
   float: right;
   margin-top: 3px;
   opacity: .8;
}

.sv-profile-progress i.question-sign:hover {
   opacity: 1;
}

.sv-profile-progress .sv-progress-bar-meter {
   position: relative;
   height: 100%;
   background-color: #4ca454;
   background-image: linear-gradient(-45deg, transparent 33%, rgba(90, 180, 45, 1) 33%, rgba(90, 180, 45, 1) 66%, transparent 66%);
   background-size: 35px 20px, 100% 100%, 100% 100%;
   border-radius:3px;
}

.sv-profile-progress.sv-profile-progress-low .sv-progress-bar-meter {
   background-color: #4169E1;
   background-image: linear-gradient(-45deg, transparent 33%, rgba(66, 139, 202, 1) 33%, rgba(66, 139, 202, 1) 66%, transparent 66%);
}

.sv-profile-progress .sv-progress-bar-value {
   position: absolute;
   top: 0;
   left: 100%;
   padding-left: 4px;
   line-height: 20px;
   white-space: nowrap;
}

.sv-profile-progress.sv-profile-progress-high .sv-progress-bar-value {
   left: auto;
   right: 5px;
   color: whiteSmoke;
}

.sv-has-value {
   color: #260;
}

.sv-no-value {
   color: #b50000;
}

.sv-completed-text {
   display: inline-block;
   vertical-align: top;
}

.modal .modal-body .modal-section-title {
   font-size: 1.2rem;
}
